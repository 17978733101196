<ng-container *ngIf="viewModel$ | async as viewModel; else skeleton">
    <ng-container [ngSwitch]="viewModel.userProfile!.pageScope">
        <app-state-scope-context-filter *ngSwitchCase="PageScope.State"
            [(districtIds)]="viewModel.selectedDistrictIds"
            [(schoolIds)]="viewModel.selectedSchoolIds"
            (searchClick)="onSearchClicked($event)"/>
        <app-district-scope-context-filter *ngSwitchCase="PageScope.District"
            [(districtIds)]="viewModel.selectedDistrictIds!"
            [(schoolIds)]="viewModel.selectedSchoolIds!"
            (searchClick)="onSearchClicked($event)"/>
        <app-school-scope-context-filter *ngSwitchCase="PageScope.School"
            [(schoolIds)]="viewModel.selectedSchoolIds!"
            (searchClick)="onSearchClicked($event)"/>
    </ng-container>
</ng-container>

<ng-template #skeleton>
    <div class="container">
        <div class="container">
            <ngx-skeleton-loader count="2" appearance="line"/>
        </div>
    </div>
</ng-template>