import { Injectable } from '@angular/core';
import { Pagination, Sorting, TableDataModel } from 'app/models/table-data-model';
import { ClassModel } from './class.model';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  public getClassesBySearchTerms(classSearchTerms: object, classPagination: Pagination, classSorting: Sorting): Observable<TableDataModel.PagedResponse<ClassModel.Class>>  {
    return of({ data: {}, pageNumber: 0, pageSize:0, totalPages: 0, totalRecords: 0 } as TableDataModel.PagedResponse<ClassModel.Class>).pipe(
      tap(() => console.debug('getClassesBySearchTerms args', { 
        'classSearchTerms': classSearchTerms,
        'classPagination': classPagination,
        'classSorting': classSorting
      }))
    );
  }

  //constructor() { }
}
