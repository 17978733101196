import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { ErrorCategory } from '../enums/error-category';
import { ErrorSubCategory } from '../enums/error-subcategory';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogServiceModel } from 'app/services/error-log/error-log.model';
import { ErrorTableComponent } from '../shared/error-table/error-table.component';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';

@Component({
  selector: 'app-error-log-staff',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ErrorTableComponent],
  templateUrl: './error-log-staff.component.html',
  styleUrl: './error-log-staff.component.scss'
})
export class ErrorLogStaffComponent {
  
  protected subCategoryErrorLabels$ = this._userProfileService.getUserProfile().pipe(
    /** For PageScope.State scopeId is not set until a district is selected
     * so the following filter cancels stream execution if scope id is falsy.
    */ 
    filter(() => this._userProfileService.isUserAuthenticated()),
    mergeMap(userProfile => this.getCounts(userProfile!).pipe(
      map(categoryErrorCounts => {
        const category = categoryErrorCounts.find(category => category.name === ErrorCategory.staff);
        return category?.subCategoryErrorCounts ?? [];
      }),
      map(subCategoryErrorCounts => {
        subCategoryErrorCounts.push({ 
          name: ErrorSubCategory.total, 
          count: subCategoryErrorCounts.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
        });
        return subCategoryErrorCounts;
      }),
      map(subCategoryErrorCounts => ({
        calender: `Calendar Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.calendar)?.count ?? 0})`,
        warning: `Warnings (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.warning)?.count ?? 0})`,
        total: `All (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.total)?.count ?? 0})`
      }))
    ))
  );

  public constructor(
    private _userProfileService: UserService,
    private _errorLogService: ErrorLogService) { }

    private getCounts(userProfile: UserProfile): Observable<ErrorLogServiceModel.CategoryErrorCount[]>{
      switch(userProfile.pageScope){
        case PageScope.State:
        case PageScope.District: {
          return this._errorLogService.getDistrictCounts(userProfile.scopeDetails.districtIds);
        }
        case PageScope.School: {
          return this._errorLogService.getSchoolCounts(userProfile.scopeDetails.schoolIds);
        }
      }
    }
}
