@if (viewModel$ | async; as viewModel) {
  @if(viewModel.navItems){
  <ng-container>
    <mat-drawer-container>
      <mat-drawer mode="side" position="end" [opened]="showPanel">
        <app-developer-pane (panelClosed)="onPanelClosed()"></app-developer-pane>
      </mat-drawer>
      <mat-drawer-content>
        @if (viewModel.profile?.isDeveloper) {
          <button (click)="togglePanel()" color="accent" style="position:absolute;right:0;top:90px;border-radius:10% 0 0 10%;background-color:rgb(224, 224, 255);color:(0, 0, 110)" mat-fab>
            <mat-icon>settings</mat-icon>
          </button>
        }
        <tdoe-layout appName="SWORD" 
            [headerNavItems]="viewModel.navItems"
            headerAppLogo="/assets/images/SWORD.png"
            [enableSearch]="true"
            searchHint="Search"
            logo="/assets/images/tn-logo_96x96.png"
            (onNavClick)="onNavClick($event)">
          <router-outlet page></router-outlet>
          <ng-container footer>
            <div class="tdoe-footer-container">

              <div class="tdoe-footer-column">
                <a [routerLink]="['/']">
                  <img class="tdoe-brand" src="/assets/tdoe-ds/logo/tdoe.svg" alt="Tennessee Department of Education">
                </a>
              </div>

              <div class="tdoe-footer-column additional-resources">
                <h4>Additional Resources</h4>
                <div><a href="https://bestforall.tnedu.gov/">Best for All Central (tnedu.gov)</a></div>
                <div><a href="https://www.tn.gov/education/districts/lea-operations/education-information-system-eis.html">Education Information System (EIS)</a></div>
              </div>

              <div class="tdoe-footer-column">
                  <p class="mat-body-strong up-size">Need assistance?</p>
                  <p class="mat-body">Having trouble using the site or need more information?</p>
                  <tdoe-button size="large" text="Contact, help and FAQ" type="secondary" onclick="window.location.href='mailto:DT.Support@tn.gov'"></tdoe-button>
              </div>

              <div class="tdoe-footer-copyright">
                  <div class="tdoe-left-col">
                      <a href="https://www.tn.gov/web-policies/privacy-statement.html" target="_blank">Privacy Policy</a>
                      <span>|</span>
                      <span>Client: {{viewModel.clientVersion}}</span>
                      @if(viewModel.apiVersion){
                        <span>|</span>
                        <span>API: {{viewModel.apiVersion}}</span>
                      }
                  </div>
                  <div class="tdoe-center-col">
                      <a href="#">
                          <svg-icon src="/assets/images/instagram-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                      </a>
                      <a href="#">
                          <svg-icon src="/assets/images/x-twitter-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                      </a>
                      <a href="#">
                          <svg-icon src="/assets/images/facebook-f-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                      </a>
                      <a href="#">
                          <svg-icon src="/assets/images/flickr-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                      </a>
                      <a href="#">
                          <svg-icon src="/assets/images/linkedin-in-brands-solid.svg" [svgStyle]="{ 'height.px': 20, 'fill': 'currentColor' }"></svg-icon>
                      </a>
                  </div>

                  <div class="tdoe-right-col">
                      <span>Copyright &copy; 2022 Tennessee Department of Education. All Rights Reserved.</span>
                  </div>

              </div>
          </div>
          </ng-container>
        </tdoe-layout>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container>
  }
  @if(!viewModel.navItems){
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"><p style="color: white">{{viewModel.loadingText}}</p></ngx-spinner>
  }
}