import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { ErrorSubCategory } from '../enums/error-subcategory';
import { PageScope } from 'app/enums/page-scope';
import { CategoryErrorCount, ErrorLogServiceModel } from 'app/services/error-log/error-log.model';
import { ErrorTableComponent } from '../shared/error-table/error-table.component';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';

@Component({
  selector: 'app-error-log-total',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ErrorTableComponent],
  templateUrl: './error-log-total.component.html',
  styleUrl: './error-log-total.component.scss'
})
export class ErrorLogTotalComponent {

  protected subCategoryErrorLabels$ = this._userProfileService.getUserProfile().pipe(
      /** For PageScope.State scopeId is not set until a district is selected
       * so the following filter cancels stream execution if scope id is falsy.
      */ 
      filter(userProfile => !!userProfile),
      mergeMap(userProfile => this.getCounts(userProfile).pipe(
        map(categoryErrorCounts => {
          const category = this.groupAndSumBySubCategory(categoryErrorCounts);
          return category?.subCategoryErrorCounts ?? [];
        }),
        map(subCategoryErrorCounts => {
          subCategoryErrorCounts.push({ 
            name: ErrorSubCategory.total, 
            count: subCategoryErrorCounts.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
          });
          return subCategoryErrorCounts;
        }),
        map(subCategoryErrorCounts => ({
          calender: `Calendar Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.calendar)?.count ?? 0})`,
          enrollment: `Enrollment Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.enrollment)?.count ?? 0})`,
          scheduling: `Scheduling Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.scheduling)?.count ?? 0})`,
          dailyReporting: `Daily Reporting Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.dailyReporting)?.count ?? 0})`,
          warning: `Warnings (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.warning)?.count ?? 0})`,
          total: `All (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.total)?.count ?? 0})`
        }))
      ))
  );
  
  public constructor(
    private _userProfileService: UserService,
    private _errorLogService: ErrorLogService) { }

  private getCounts(userProfile: UserProfile): Observable<ErrorLogServiceModel.CategoryErrorCount[]>{
    switch(userProfile.pageScope){
      case PageScope.State:{
        return this._errorLogService.getStateCounts();
      }
      case PageScope.District: {
        return this._errorLogService.getDistrictCounts(userProfile.scopeDetails.districtIds);
      }
      case PageScope.School: {
        return this._errorLogService.getSchoolCounts(userProfile.scopeDetails.schoolIds);
      }
    }
  }

  private groupAndSumBySubCategory(data: ErrorLogServiceModel.CategoryErrorCount[]): CategoryErrorCount {
    const allSubCategories = data.flatMap(category => category.subCategoryErrorCounts);

    const groupedSubCategories = allSubCategories.reduce((acc, subCategory) => {
      const existing = acc.find(item => item.name === subCategory.name);
      if (existing) {
        existing.count += subCategory.count;
      } else {
        acc.push({ ...subCategory });
      }
      return acc;
    }, [] as ErrorLogServiceModel.ErrorCount[]);

    const totalCount = groupedSubCategories.reduce((acc, subCategory) => acc + subCategory.count, 0);

    const totalCategory: CategoryErrorCount = {
      name: 'total',
      count: totalCount,
      subCategoryErrorCounts: groupedSubCategories
    };

    return totalCategory;
  }
}
