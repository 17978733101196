import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorLogService, ErrorLogModel } from 'app/services/error-log/error-log.service';
import { BehaviorSubject, Observable, catchError, combineLatest, filter, map, mergeMap, of, startWith} from 'rxjs';
import { ErrorCategoryTabsModel } from './error-category-tabs.model';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { PageScope } from 'app/enums/page-scope';
import { UserModel } from 'app/services/user/user.model';

@Component({
  selector: 'app-error-category-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-category-tabs.component.html',
  styleUrl: './error-category-tabs.component.scss'
})
export class ErrorCategoryTabsComponent {

  @Input()
  public pageScope!: PageScope;

  @Input()
  public set scopeDetail(scopeDetails: UserModel.scopeDetails) {
    if(scopeDetails){
      this._scopeDetailSubject$.next(scopeDetails);
    }
  }
  
  @Output()
  public errorCategoryTabSelected = new EventEmitter<string>();

  private _scopeDetailSubject$ = new BehaviorSubject<UserModel.scopeDetails | undefined>(undefined);

  private _selectedTabName$: Observable<string> = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getCurrentPageName(this._activatedRoute.snapshot)),
      startWith(this.getCurrentPageName(this._activatedRoute.snapshot))
    );
  
  protected errorCategoryTabs$: Observable<ErrorCategoryTabsModel.ErrorCategoryTab[]> =
    combineLatest([this._scopeDetailSubject$.pipe(filter(id => id !== undefined)), this._selectedTabName$]).pipe(
      map(([scopeDetails, selectedTabName]) => ({scopeDetails, selectedTabName})),
      mergeMap(data => this.getErrorCounts(this.pageScope, data.scopeDetails!).pipe(
        map(errorCounts => this.transformCategoryErrorCounts(errorCounts, data.selectedTabName))
      )),
      catchError(error => {
        console.error('Error in errorCategoryTabs$', error);
        return of([]);
      })
    );


  public constructor (
    private _errorLogService: ErrorLogService,
    private _router: Router, 
    private _activatedRoute: ActivatedRoute
  ) {
    //
  }

  protected onTabClick(selectedTab: ErrorCategoryTabsModel.ErrorCategoryTab): void {
    this.errorCategoryTabSelected.emit(selectedTab.name.toLowerCase());
  }

  private transformCategoryErrorCounts(errorCounts: ErrorLogModel.CategoryErrorCount[], selectedTabName: string): ErrorCategoryTabsModel.ErrorCategoryTab[] {
    return Object.entries(ErrorLogService.convertToErrorCounts(errorCounts))
      .map(([key, value]) => ({
        name: StringUtilities.capitalizeFirstLetter(key),
        count: value.toString(),
        selected: key === selectedTabName
      }));
  }

  private getCurrentPageName (activatedRoute: ActivatedRouteSnapshot): string {
    return this.getLastChild(activatedRoute).routeConfig?.path ?? '';
  }

  private getLastChild(activatedRoute: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if(activatedRoute.children.length > 0 && activatedRoute.firstChild) {
      return this.getLastChild(activatedRoute.firstChild);
    }
    return activatedRoute;
  }

  private getErrorCounts(pageScope: PageScope, scopeDetail: {
      districtIds: string[];
      schoolIds: string[];
  }): Observable<ErrorLogModel.CategoryErrorCount[]>{
    switch(pageScope){
      case PageScope.State:
      case PageScope.District: {
        return this._errorLogService.getDistrictCounts(scopeDetail.districtIds);
      }
      case PageScope.School: {
       return this._errorLogService.getSchoolCounts(scopeDetail.schoolIds);
      }
      default: {
        throw new Error('User has no page scope.');
      }
    }
  }
}
