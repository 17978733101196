import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingInterceptor, TdoeDsModule, TdoeToastComponent } from '@tdoe/design-system';
import { ToastrModule } from 'ngx-toastr';
import { TimeagoModule } from 'ngx-timeago';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module'; import { LandingComponent } from './components/home/landing/landing.component';
import { HeroAboutComponent } from './components/home/landing/hero-about/hero-about.component';
import { HeroWelcomeComponent } from './components/home/landing/hero-welcome/hero-welcome.component';
import { AboutComponent } from './components/home/about/about.component';

import { ContentCardComponent } from './components/shared/content-card/content-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { HeroComponent } from './components/shared/hero/hero.component';
import { ProgramParticipationOneComponent } from './components/home/program-participation/program-participation.component';
import { GradeLevelComponent } from './components/home/grade-level/grade-level.component';
import { DataLookupSchoolComponent } from './components/data-lookup/data-lookup-school/data-lookup-school.component';
import { DataLookupStaffComponent } from './components/data-lookup/data-lookup-staff/data-lookup-staff.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule, provideAngularSvgIcon } from 'angular-svg-icon';
import { MatInputModule } from '@angular/material/input';
import { developerInterceptor } from './developer.interceptor';
import { DeveloperPaneComponent } from './components/shared/developer-pane/developer-pane.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
import { FormlyMaterialModule } from '@ngx-formly/material';

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        HeroAboutComponent,
        HeroWelcomeComponent,
        AboutComponent,
        ContentCardComponent,
        HeroComponent,
        ProgramParticipationOneComponent,
        GradeLevelComponent,
        DataLookupSchoolComponent,
        DataLookupStaffComponent,
        DeveloperPaneComponent
    ],
    bootstrap: [AppComponent],
    imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TdoeDsModule.forRoot({
        ...environment.authConfig
    }),
    TimeagoModule.forRoot(),
    ToastrModule.forRoot({
        toastComponent: TdoeToastComponent
    }),
    MsalModule,
    RouterModule.forRoot([]),
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule,
    NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
    }),
    NgxSpinnerModule,
    AngularSvgIconModule.forRoot(),
    MatSidenavModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlySelectModule,
    FormlyMaterialModule,
    FormlyMatSelectModule
],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([
                developerInterceptor
            ])
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        },
        provideAngularSvgIcon()
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class AppModule { }
