import { Component} from '@angular/core';
import { TdoeDsModule } from '@tdoe/design-system';
import { Pagination, Sorting, TableDataModel } from 'app/models/table-data-model';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, of, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';

import { UserService } from 'app/services/user/user.service';
import { PageScope } from 'app/enums/page-scope';
import { SchoolService } from 'app/services/school/school.service';
import { ClassModel } from 'app/services/class/class.model';
import { ClassService } from 'app/services/class/class.service';
import { ClassTableComponent } from '../shared/class-table/class-table.component';
import { ClassFilterComponent } from '../shared/class-filter/class-filter.component';

@Component({
  selector: 'app-data-lookup-class',
  templateUrl: './data-lookup-class.component.html',
  styleUrl: './data-lookup-class.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ClassTableComponent,
    ClassFilterComponent,
    ScopeContextFilterComponent,
    TdoeDsModule
  ]
})
export class DataLookupClassComponent {

  private _classTablePaginationSubject$ = new BehaviorSubject({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private _classTableSortingSubject$ = new BehaviorSubject({} as TableDataModel.Sorting);
  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private _classSearchTermsSubject$ = new BehaviorSubject<object>({});

  private _scopeContextFilterSelectedData$ = this._userProfileService.getUserProfile().pipe(
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      filter(userProfile => !! userProfile),
      startWith({
        pageScope: PageScope.State,
        year: new Date().getFullYear(),
        schoolId: 0,
        districtId: 0
      }),
      distinctUntilChanged((prev, curr) => this.isCurrentScopeFilterSelectedDataEqualToCurrent(prev, curr)),
      map(searchTerms => ({
        sessionState: userProfile,
        ...searchTerms!
      })),
      shareReplay(1)
    ))
  );

  private _selectedScopeDescriptor$ = this._scopeContextFilterSelectedData$.pipe(
    switchMap(scopeContextFilterData => {
      switch(scopeContextFilterData.pageScope){
        case PageScope.State: {
          return of(`${scopeContextFilterData.year} - Statewide`);
        }
        case PageScope.District: {
          return this._schoolService.getDistrict(0).pipe(
            map(district => `${scopeContextFilterData.year} - ${district.name}`)
          );
        }
        case PageScope.School: {
          return this._schoolService.getSchool(0).pipe(
            map(school => `${scopeContextFilterData.year} - ${school.name}`)
          );
        }
      }
    })
  );

  private _classesPagedResponse$ = combineLatest([
    this._classSearchTermsSubject$,
    this._classTablePaginationSubject$,
    this._classTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    map(([classSearchTerms, classPagination, classSorting]) => ({classSearchTerms, classPagination, classSorting})),
    switchMap(data => 
      this._classService.getClassesBySearchTerms(data.classSearchTerms, data.classPagination, data.classSorting).pipe(
        map((studentsPagedResponse: TableDataModel.PagedResponse<ClassModel.Class>) => studentsPagedResponse)
    )),
  );

  protected viewModel$ = combineLatest([
    this._userProfileService.getUserProfile(), 
    this._scopeContextFilterSelectedData$, 
    this._classesPagedResponse$, 
    this._selectedScopeDescriptor$ ]).pipe(
    map(([
      userProfile, 
      scopeContextFilterSelectedData, 
      classesPagedResponse, 
      selectedScopeDescriptor]) => ({ 
        userProfile, 
        scopeContextFilterSelectedData, 
        classesPagedResponse, 
        selectedScopeDescriptor
      })),
    map(data => ({
      userPageScope: PageScope.State,
      userScopeId: 0,
      selectedPageScope: data.scopeContextFilterSelectedData.pageScope,
      selectedYear: data.scopeContextFilterSelectedData.year,
      //selectedSchoolId: data.scopeContextFilterSelectedData.schoolId,
      //selectedDistrictId: data.scopeContextFilterSelectedData.districtId,
      classData: data.classesPagedResponse?.data ?? [],
      totalRecords: data.classesPagedResponse?.totalRecords ?? 0,
      selectedScopeDescriptor: data.selectedScopeDescriptor,
      studentFilterTitle: this.getClassFilterTitle(data.scopeContextFilterSelectedData.pageScope)
    })),
    shareReplay(1)
  );

  public constructor(
    private _classService: ClassService,
    private _userProfileService: UserService,
    private _schoolService: SchoolService
  ){}

  public onContextFilterSearchClicked(selectedData: ScopeContextFilterModel.SelectedData): void {
    this._scopeContextFilterSelectedDataSubject$.next(selectedData);
  }

  public onSortClicked(sorting: Sorting): void {
    this._classTableSortingSubject$.next(sorting);
  }

  public onPageChanged(pagination: Pagination): void {
    this._classTablePaginationSubject$.next(pagination);
  }

  //Clark: move closer to ScopeContextFilterModel.SelectedData so it can be reused
  private isCurrentScopeFilterSelectedDataEqualToCurrent(previous: ScopeContextFilterModel.SelectedData, current: ScopeContextFilterModel.SelectedData): boolean {
    console.debug('onStudentFilterSearchClicked -> isCurrentScopeFilterSelectedDataEqualToCurrent:', previous, current);
    return false;
    // previous.year === current.year
    //   && previous.districtId === current.districtId
    //   && previous.schoolId === current.schoolId;
  }

  private getClassFilterTitle(selectedPageScope: PageScope): string {
    switch(selectedPageScope){
      case PageScope.State: {
        return 'Statewide Search';
      }
      case PageScope.District: {
        return 'District Search';
      }
      case PageScope.School: {
        return 'School Search';
      }
    }
  }

  protected onStudentTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._classTableSortingSubject$.next(sorting);
  }

  protected onStudentTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._classTablePaginationSubject$.next(pagination);
  }

  // Should receive the class service model -> classSearchTerms
  protected onClassFilterSearchClicked(classSearchTerms: ClassModel.ClassSearchTerms): void {
    this._classSearchTermsSubject$.next(classSearchTerms);
  }

  protected onClassFilterResetClicked(): void {
    this._classSearchTermsSubject$.next({});
  }
}
