import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from './services/user/user.service';
import { map, switchMap, take} from 'rxjs';

export const developerInterceptor: HttpInterceptorFn = (req, next) => { 

    const userService = inject(UserService);

    return userService.getUserProfile()
        .pipe(
            take(1),
            map(profile => {
                let modifiedReq = req;

                if (profile?.districtClaims) {
    
                    if (profile?.districtClaims.length > 0) {
                        modifiedReq = modifiedReq.clone({
                            setHeaders: {
                                'x-tdoe-districts': profile.districtClaims
                            }
                        });
                    }
                }
            
                if (profile?.schoolClaims) {
            
                    if (profile?.schoolClaims?.length > 0) {
                        modifiedReq = modifiedReq.clone({
                            setHeaders: {
                                'x-tdoe-schools': profile.schoolClaims
                            }
                        });
                    }
                }

                return modifiedReq;
            }),
            switchMap(modifiedReq => next(modifiedReq))
        );
};
