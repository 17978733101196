import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { UserModel, UserProfile } from './user.model';
import { MsalService } from '@azure/msal-angular';
import { CacheService } from '../cache-service/cache.service';
export { UserModel } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private GetRoles(): string[] {
    const idTokenClaims = this._msalService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      const idTokenClaimValues = (idTokenClaims['scopedRoles'] ?? []);

      if(Array.isArray(idTokenClaimValues)){
        return (idTokenClaimValues as string[]).concat(idTokenClaims?.roles ?? []);
      }
      return ([idTokenClaimValues as string]).concat(idTokenClaims?.roles ?? []);
    }
    return [];
  }

  public constructor(private _msalService: MsalService,
                     private cacheService: CacheService
  ) { }

  public isUserAuthenticated(): boolean {
    const account = this._msalService.instance.getActiveAccount();
    return !!account;
  }

  public getUserProfile(): Observable<UserModel.UserProfile | undefined> {
    return of(this.isUserAuthenticated()? new UserProfile(this.cacheService, this.GetRoles()) : undefined).pipe(shareReplay(1));
  }
}
