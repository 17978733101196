/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoggingService } from '@tdoe/design-system';
import { District, School } from 'app/dto';
import { CacheService } from 'app/services/cache-service/cache.service';
import { SchoolService } from 'app/services/school/school.service';
import { createScopeClaim } from 'app/utilities/string-utilities/string-utilities';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-developer-pane',
  templateUrl: './developer-pane.component.html',
  styleUrl: './developer-pane.component.scss'
})
export class DeveloperPaneComponent {
  @Output()
  public panelClosed = new EventEmitter();

  public formlyForm = new FormGroup({});
  public formlyModel: any = {};

  public formlyFields: FormlyFieldConfig[] = [
    {
      key: 'selectedDistricts',
      type: 'select',
      props: {
        label: 'Districts',
        options: this.schoolService.getDistricts(),
        labelProp: 'name',
        valueProp: 'districtId',
        multiple: true
      },
    },
    {
      key: 'selectedSchools',
      type: 'select',
      props: {
        label: 'Schools',
        labelProp: 'name',
        valueProp: 'schoolId',
        multiple: true
      },
      expressions: {
        'hide': '!model.selectedDistricts || model.selectedDistricts.length < 1'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          if (this.formlyModel.selectedDistricts) {
            field.props!.options = this.schoolService.getSchools(this.formlyModel.selectedDistricts);
          }

          ((field.parent as any).get('selectedDistricts').formControl as FormControl).valueChanges.forEach(async _ => {
            field.props!.options = this.schoolService.getSchools(_);
          });
        }
      }
    }
  ];

  public constructor(private schoolService: SchoolService,
                     //private userService: UserService,
                     private cacheService: CacheService,
                     private logger: LoggingService) {

    this.formlyModel.selectedDistricts = cacheService.devScopeDistricts?.map(district => district.districtId);
    this.formlyModel.selectedSchools = cacheService.devScopeSchools?.map(school => school.schoolId);
  }

  private updateDevClaims(selectedDistricts: District[], selectedSchools: School[]): void {

    const devClaims: string[] = [];

    if (selectedDistricts && selectedDistricts.length > 0) {
      devClaims.push(...selectedDistricts.map(_ => createScopeClaim(_)));
    }

    if (selectedSchools && selectedSchools.length > 0) {
      devClaims.push(...selectedSchools.map(_ => createScopeClaim(_)));
    }
    //const profile = this.userService.userProfile.value;
    //this.userService.userProfile.next(profile);

    this.cacheService.devScopeDistricts = selectedDistricts;
    this.cacheService.devScopeSchools = selectedSchools;

  }

  public async submit(): Promise<void> {
    this.logger.debug('DeveloperPaneComponent -> submit', {
        title: 'ENTER',
        data: {
            formly: this.formlyFields
        }
    });

    const districts = await firstValueFrom(this.formlyFields.find(property => property.key === 'selectedDistricts')?.props?.options as Observable<any>) as District[];
    const schools = await firstValueFrom(this.formlyFields.find(property => property.key === 'selectedSchools')?.props?.options as Observable<any>) as School[];

    const selectedDistricts = districts.filter(district => this.formlyModel.selectedDistricts.includes(district.districtId));
    const selectedSchools = schools.filter(school => this.formlyModel.selectedSchools.includes(school.schoolId));

    this.logger.info('DeveloperPaneComponent -> submit', {
        title: 'EXIT',
        data: {
          districts,
          schools,
          selectedDistricts,
          selectedSchools,
          formlyModel: this.formlyModel
        }
    });

    this.updateDevClaims(selectedDistricts, selectedSchools);

    this.panelClosed.emit();
  }
}
