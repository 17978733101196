import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageScope } from 'app/enums/page-scope';
import { StudentModel, StudentService } from 'app/services/student/student.service';
import { UserModel } from 'app/services/user/user.model';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { BehaviorSubject, Observable, combineLatest, filter, debounceTime, map, mergeMap } from 'rxjs';

interface ViewModel {
  chartOptions: EChartsOption
}

@Component({
  selector: 'app-race',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './race.component.html',
  styleUrl: './race.component.scss'
})
export class RaceComponent {
  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input()
  public set scopeDetail(scopeDetails: UserModel.scopeDetails| undefined) {
    this._scopeDetailSubject$.next(scopeDetails);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  private _scopeDetailSubject$ = new BehaviorSubject<UserModel.scopeDetails | undefined>(undefined);

  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private _colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private _chartOptions: EChartsOption = {
    title: {
      text: 'Race',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      bottom: 10,
      itemGap: 15,
    },     
    grid: {
      top: 60,
      bottom: 60,
      left: '10%',
      right: '10%'
    }
  };

  private _pieSeriesOption: PieSeriesOption =  {
    name: 'Race',
    type: 'pie',
    radius: ['35%', '50%'],
    center: ['50%','40%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    labelLine: {
      show: false
    }
  };

  protected viewModel$: Observable<ViewModel>  =
    combineLatest([
      this._scopeDetailSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeDetails, pageScope, year]) => ({scopeDetails, pageScope, year})),
      mergeMap(data => this.getStudentEthnicitySummary(data.year, data.pageScope, data.scopeDetails).pipe(
        map(ethnicitySummaries => ({
          chartOptions: {
            ...this._chartOptions,
            legend: {
              ... this._chartOptions.legend,
              formatter: (name: string): string => {
                return this.getLegendLabel(name, ethnicitySummaries);
              },
              data: ethnicitySummaries                
                // sorts the legend by count ascending 
                .sort((a, b) => b.count - a.count )
                .map(ethnicitySummary => ethnicitySummary.ethnicity)
            },
            series: this.getPieSeries(ethnicitySummaries)
          }
        }))
      ))
    );

    public constructor(private _studentService: StudentService){
      //
    }

    public getStudentEthnicitySummary(year_: number, pageScope_: PageScope, scopeDetail?: UserModel.scopeDetails) : Observable<StudentModel.EthnicitySummary[]> {

      switch(pageScope_){
        case PageScope.School: {
          if ((scopeDetail?.schoolIds?.length ?? 0) > 0) {
            return this._studentService.getSchoolEthnicitySummary(year_, scopeDetail!.schoolIds);
          }
          throw new Error('scopeId does not have a value when pageScope_ is school-scope.');
        }
        case PageScope.District: {
          if ((scopeDetail?.districtIds?.length ?? 0) > 0) {
            return this._studentService.getDistrictEthnicitySummary(year_, scopeDetail!.districtIds);
          }
          throw new Error('scopeId does not have a value when pageScope_ is district-scope.');
        }
        case PageScope.State: {
          return this._studentService.getStateEthnicitySummary(year_);
        }
      }
    }

    private getPieSeries(ethnicitySummaries: StudentModel.EthnicitySummary[]): PieSeriesOption {
      this._pieSeriesOption.data = ethnicitySummaries
        .map((ethnicitySummary, index)=> ({
          value: ethnicitySummary.count,
          name: ethnicitySummary.ethnicity,
          itemStyle: (index < this._colorPalette.length) ? {
            color: this._colorPalette[index]
          } : undefined
        }));

      return this._pieSeriesOption;
    }

    private getLegendLabel(name: string, ethnicitySummaries: StudentModel.EthnicitySummary[]): string {
      const data = ethnicitySummaries.find((item) => item.ethnicity === name);
      const total = ethnicitySummaries.reduce((acc, item) => acc + item.count, 0);
      const percentage = ((data?.count ?? 0) / total) * 100;
      return `${name}: ${percentage.toFixed(2)}%`;
    }
}
