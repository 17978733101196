import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScopeContextFilterModel } from '../scope-context-filter.model';
import { StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { TdoeDsModule } from '@tdoe/design-system';
import { BehaviorSubject, combineLatest, firstValueFrom, map, mergeMap, Observable, of } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { District, School } from 'app/dto';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

 interface viewModel {
  selectedYear: number;
  selectedDistrictIds: string[] | undefined;
  selectedSchoolIds: string[] | undefined;
  /** is the state of the component valid so that when the search button is clicked a value can be emitted */
  isValid: boolean;
  years: string[];
  districts: District[];
  schools: School[] | never[];
}

@Component({
  selector: 'app-district-scope-context-filter',
  templateUrl: './district-scope-context-filter.component.html',
  styleUrls: ['./district-scope-context-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule, 
    CommonModule,
    TdoeDsModule,
    MatFormFieldModule,
    MatSelectModule
  ]
})
export class DistrictScopeContextFilterComponent implements OnInit {
    @Input()
    public set year(year: number | undefined) {
      if(year){
        this._selectedYearSubject.next(year);
      }
    }

    @Input({required: true})
    public set districtIds(districtIds: string[] ) {
      this._selectedDistrictIdSubject.next(districtIds);
    }
    @Output() public districtIdsChange = new EventEmitter<string[] | undefined>();

    @Input()
    public set schoolIds(schoolIds: string[] | undefined) {
      if(schoolIds){
        this._selectedSchoolIdSubject.next(schoolIds);
      }
    }
    @Output() public schoolIdsChange = new EventEmitter<string[] | undefined>();
    
    @Output()
    public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

    private _selectedYearSubject = new BehaviorSubject<number>(new Date().getFullYear()); 
    private _selectedDistrictIdSubject = new BehaviorSubject<string[] | undefined>(undefined); 
    private _selectedSchoolIdSubject = new BehaviorSubject<string[] | undefined>(undefined); 

    protected viewModel$ : Observable<viewModel> = combineLatest([
      this._studentService.getAvailableYears(), 
      this._schoolService.getDistricts(),
      this._selectedDistrictIdSubject,
      this._selectedSchoolIdSubject,
      this._selectedYearSubject
    ]).pipe(
      map(([years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear]) => ({years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear})),
      mergeMap(data => this.getSchools(data.selectedDistrictIds).pipe(
        map(schools => ({
          selectedYear: data.selectedYear,
          selectedDistrictIds: data.selectedDistrictIds,
          selectedSchoolIds: data.selectedSchoolIds,
          years: data.years,
          districts: data.districts,
          schools: schools,
          isValid: data.selectedYear !== undefined
              && data.selectedDistrictIds !== undefined
        }))
      ))
    );

    protected searchTerms?: ScopeContextFilterModel.SelectedData;

    public constructor(
      private _studentService: StudentService,
      private _schoolService: SchoolService) { }

    private getSchools(selectedDistrictIds?: string[]): Observable<School[]> {
      return selectedDistrictIds 
        ? this._schoolService.getSchools(selectedDistrictIds)
        : of([]);
    }

    public async ngOnInit(): Promise<void> {
        const viewModel = await firstValueFrom(this.viewModel$);
        if (viewModel.districts && viewModel.districts.length === 1) {
          this.onDistrictIdChange({ value: viewModel.districts.map(_ => _.districtNumber) } as MatSelectChange);
          viewModel.schools = await firstValueFrom(this._schoolService.getSchools(viewModel.selectedDistrictIds ?? []));
        }
    }

    protected onDistrictIdChange(e: MatSelectChange) : void {
      this._selectedDistrictIdSubject.next(e.value);
      this._selectedSchoolIdSubject.next(undefined);
    }

    protected onSchoolChange(e: MatSelectChange): void {
      this._selectedSchoolIdSubject.next(e.value);
    }

    protected onYearChange(year: number): void {
      this._selectedYearSubject.next(year);
    }

    protected onSearchClick(viewModel: viewModel): void {
      if(viewModel.selectedDistrictIds){
        this.searchClick.emit({ 
          year: viewModel.selectedYear, 
          districtIds: viewModel.selectedDistrictIds, 
          schoolIds: viewModel.selectedSchoolIds,
          pageScope: viewModel.selectedSchoolIds
          ? PageScope.School 
          : PageScope.District
        });
      }
    }
}


