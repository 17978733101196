/* eslint-disable @typescript-eslint/no-explicit-any */

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StudentModel } from '../../../../services/student/student.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { Router } from '@angular/router';
import { TableDataModel } from 'app/models/table-data-model';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { ChipItem, TdoeDsModule } from '@tdoe/design-system';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';
import { PageScope } from 'app/enums/page-scope';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FilterModalComponent } from './filter-modal/filter-modal/filter-modal.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrl: './student-table.component.scss',
  standalone: true,
  imports: [
    AdditionalInfoComponent, 
    CommonModule, 
    DatePipe, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    SsidFormatPipe,
    TdoeDsModule,
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    MatDialogModule,
    TdoeDsModule
  ],
  host: { class: 'sword-table' } 
})
export class StudentTableComponent implements AfterViewInit {

  @Input({required: true})
  public set totalRecords( totalRecords: number) {
    this._totalRecordsSubject$.next(totalRecords);
    this._changeDetector.markForCheck();
  }

  @Input({required: true})
  public set students(students: StudentModel.Student[]) {
    this._studentSubject$.next(students);
    this._changeDetector.markForCheck();
  }

  @Input()
  public set pageScope(pageScope: PageScope){
    this._pageScopeSubject$.next(pageScope);
    this._changeDetector.markForCheck();
  }

  @Input()
  public set selectedDistrictIds(value: string[] | undefined) {
    this._selectedDistrictIds$.next(value);
  }

  @Input()
  public set selectedSchoolIds(value: string[] | undefined) {
    this._selectedSchoolIds$.next(value);
  }

  @Output()
  public pageChanged = new EventEmitter<TableDataModel.Pagination>();

  @Output()
  public sortClicked = new EventEmitter<TableDataModel.Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  private _selectedDistrictIds$ = new BehaviorSubject<string[] | undefined>(undefined);
  private _selectedSchoolIds$ = new BehaviorSubject<string[] | undefined>(undefined);
  private _totalRecordsSubject$ = new BehaviorSubject<number>(0);
  private _studentSubject$ = new BehaviorSubject<StudentModel.Student[]>([]);
  private _dynamicColumnsSubject$ = new BehaviorSubject<AdditionalInfoModel.Field[]>([]);
  private _pageScopeSubject$ = new BehaviorSubject<PageScope>(PageScope.State);
  private _dataSource = new MatTableDataSource<StudentModel.Student>();
  private _columns = [ 'name', 'ssid', 'dateOfBirth', 'grade', 'enrollmentStartDate', 'enrollmentEndDate', 'code'];

  protected filterChips?: ChipItem[];
  protected showFilters = false;

  protected viewModel$ = combineLatest([
      this._studentSubject$, 
      this._totalRecordsSubject$, 
      this._dynamicColumnsSubject$, 
      this._pageScopeSubject$,
      this._selectedDistrictIds$,
      this._selectedSchoolIds$]).pipe(
    map(([students, totalRecords, dynamicColumns, pageScope, selectedDistrictIds, selectedSchoolIds]) => ({students, totalRecords, dynamicColumns, pageScope, selectedDistrictIds, selectedSchoolIds})),
    tap(data => {
      console.debug('data:', data);
      data.dynamicColumns.forEach(col => {
        if (col.filter) {
          col.filter.hooks = {
            ...col.filter.hooks,
            onInit: (field: FormlyFieldConfig): void => {
              field.formControl?.valueChanges.subscribe({
                next: val => this.hookFilter(field, val),
                error: (err) => console.error(err)
              });
            }
          };
        }
      });
    }),
    map(data => {
      this._dataSource.data = data.students;
      return ({
        dataSource: this._dataSource,
        displayedColumns: [
          ...this._columns,
          ...this.getContextColumns(data.pageScope, data.selectedDistrictIds, data.selectedSchoolIds),
          ...data.dynamicColumns.map(field => field.key),
        ],
        totalRecords: data.totalRecords,
        dynamicColumns: data.dynamicColumns,
        selectedDistrictIds: data.selectedDistrictIds,
        selectedSchoolIds: data.selectedSchoolIds
      });
    }),
    tap(viewModel => console.debug('viewModel:', viewModel))
  );

  public constructor(
    private _router: Router,
    private _changeDetector: ChangeDetectorRef,
    private dialogs: MatDialog
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    this._dataSource.filterPredicate = (student, filter): boolean => {
      
      const filterContext = JSON.parse(filter);
      
      let matchCount = 0;
      let filterCount = 0;

      Object.keys(filterContext).forEach((value) => {
        filterCount++;
        const filterValue = filterContext[value];
        const studentValue: any = (student as Record<string, any>)[value];

        if (context.compareValues(filterValue, studentValue)) {
          matchCount++;
        }
      });

      return matchCount === filterCount;
    };
  }

  protected formly = new FormGroup({});
  protected filterModel: any = {};

  protected contextFilters: { [key: string]: FormlyFieldConfig } = {
    'grade': {
      key: 'grade',
      type: 'select',
      props: {
        options: [
          {
            label: 'K',
            value: 'K'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '9',
            value: '9'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '11',
            value: '11'
          },
          {
            label: '12',
            value: '12'
          }
        ],
        label: 'Grade',
        multiple: true
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'ssid': {
      key: 'ssid',
      type: 'input',
      props: {
        type: 'text',
        label: 'SSID'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'name': {
      key: 'name',
      type: 'input',
      props: {
        type: 'text',
        label: 'Name'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'dateOfBirth': {
      key: 'dateOfBirth',
      type: 'datepicker',
      props: {
        label: 'Birth date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'enrollmentStartDate': {
      key: 'enrollmentStartDate',
      type: 'datepicker',
      props: {
        label: 'Enrollment start date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'enrollmentEndDate': {
      key: 'enrollmentEndDate',
      type: 'datepicker',
      props: {
        label: 'Enrollment end date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'district': {
      key: 'district',
      type: 'input',
      props: {
        label: 'District',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'code': {
      key: 'code',
      type: 'input',
      props: {
        label: 'Code',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'school': {
      key: 'school',
      type: 'input',
      props: {
        label: 'School',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    }
  };

  protected getInlineFilter(name: string): FormlyFieldConfig[] {
    const field = this.contextFilters[name];
    delete field.props?.label;
    return [field];
  }

  private compareValues(sourceValue: any, targetValue: any): boolean {
    if (Array.isArray(sourceValue)) {
      if ((sourceValue as string[]).includes(targetValue)) {
        return true;
      }
    } else if (dayjs(sourceValue.toString()).isValid()) {
      if (dayjs(sourceValue).isSame(targetValue, 'day')) {
        return true;
      }
    } else if (typeof sourceValue === 'string') {
      if ((targetValue as string).toLowerCase().includes(sourceValue.toLowerCase())) {
        return true;
      }
    }

    return false;
  }

  protected filterChipRemoved(filter: ChipItem): void {
    this.filterInPlace({ name: 'RESET', key: filter.text.split(':')[0] });
    this.filterChips = this.filterChips?.filter(_ => !_.text.startsWith(filter.text));
  }

  private filterInPlace(field: FormlyFieldConfig): void {
    const key = field.key as string;
    if (field.name !== 'RESET') {
      Object.assign(this.filterModel, field.model);
    } else {
      delete this.filterModel[key];
    }
    this._dataSource.filter = JSON.stringify(this.filterModel);

    if (this.filterModel) {
      this.filterChips = Object.keys(this.filterModel).map(key => ({
        text: `${key}: '${this.filterModel[key]}'`,
        persistent: false
      } as ChipItem));
    } else {
      delete this.filterChips;
    }

  }

  private hookFilter(field: FormlyFieldConfig, val: any): void {
    Object.assign(this.filterModel, { [field.key as string]: val });
    this.filterInPlace(field);
  }

  protected inlineFilter(field: FormlyFieldConfig): FormlyFieldConfig[] {
    if (field.props) {
      delete field.props['appearance'];
      delete field.props.label;
    }
    return [field];
  }

  public ngAfterViewInit(): void {
    this._changeDetector.detectChanges();
  }

  public onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoFieldSelected(categories: AdditionalInfoModel.Category[]): void {
    this._dynamicColumnsSubject$.next(
      categories.flatMap(_ => _.fields)
        .filter(field => field.selected));
  }

  protected onFilterClick(e: Event, field?: FormlyFieldConfig): void {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    console.debug({
      e,
      field,
      _this: this
    });

    const dialogRef = this.dialogs.open<FilterModalComponent, FormlyFieldConfig, FormlyFieldConfig>(FilterModalComponent, {
      data: field
    });

    dialogRef.afterClosed().subscribe({
      next: (filter) => {
        if (filter) {
          this.filterInPlace(filter);
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  protected onPageSizeChange($event: Event) : void {
    const pageSizeSelect = $event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value));
  }
  
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onRowClicked(student: StudentModel.Student): void {
    this._router.navigate(['data-lookup','student-view', student.id, StringUtilities.FormatStringForUrl(student.name)]);
  }

  private getContextColumns(pageScope: PageScope, selectedDistrictIds: string[] | undefined, selectedSchoolIds: string[] | undefined): string[] {
    switch(pageScope){
      case PageScope.State: {
        return ['school', 'district'];
      }
      case PageScope.District: {
        if ((selectedDistrictIds?.length ?? 0) > 1) return ['school', 'district'];
        return ['school'];
      }
      default: {
        if ((selectedSchoolIds?.length ?? 0) > 1) return ['school'];
        return [];
      }
    }
  }
}
